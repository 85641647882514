<template>
  <KPage fluid>
    <!-- Barra de busca -->
    <FilterBar :page="page" />

    <!--  -->
    <CollectionsList :items="items" />

    <!-- Barra de paginação -->
    <PaginationBar :pagination="pagination" v-model="page" />

    <!-- Separa o rodapé do botão de cadastrar -->
    <v-row style="height: 38px"></v-row>

    <DialogReport ref="dialog_report" />

    <SpeedDial
      :actions="speedDialActions"
      @click:batches="onBatchesClick"
      @click:report="onReportClick"
    />
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SpeedDial from "@/components/SpeedDial";
import PaginationBar from "@/components/Pagination/Bar";

import FilterBar from "./FilterBar";
import CollectionsList from "./CollectionsList";
import DialogReport from "./DialogReport";

export default {
  components: {
    KPage,
    SpeedDial,
    PaginationBar,

    FilterBar,
    DialogReport,
    CollectionsList
  },

  data() {
    return {
      //
      loading: false,

      //
      dialog: {
        visible: true
      },

      // Dados dos filtros
      query: {},

      page: 1,

      speedDialActions: [
        {
          eventName: "click:batches",
          label: "Novo Lote de Solicitações",
          icon: "mdi-plus-box-multiple"
        },
        {
          eventName: "click:report",
          label: "Relatório",
          icon: "mdi-file-excel"
        }
      ]
    };
  },

  watch: {
    pagination(val, old) {
      if (old.page != val.page) this.page = val.page;
    }
  },

  computed: {
    pagination() {
      return this.$store.getters["collections/pagination"];
    },
    condominiums() {
      return this.$store.getters["condominiums/all"];
    },
    items() {
      return this.$store.getters["collections/all"];
    }
  },

  //
  methods: {
    onBatchesClick() {
      this.$router.push("/collect/collections/batch");
    },

    onReportClick() {
      this.$refs.dialog_report.open();
    },

    /**
     * Carrega os dados pela primeira vez
     */
    async loadData() {
      try {
        this.loading = true;
        this.$loading.show();

        await Promise.all([
          this.$store.dispatch("collections/all"),
          this.$store.dispatch("condominiums/all")
        ]);

        this.$loading.hide();
        this.loading = false;
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
        this.loading = false;
      }
    }
  },

  beforeMount() {
    this.loadData();
  }
};
</script>
