<template>
  <KDialog
    title="Exportar Relatório"
    :actions="dialogActions"
    :visible="visible"
    :loading="loading"
    @click:close="visible = false"
    @click:download="onDownloadClick"
  >
    <KForm ref="form" class="mt-1">
      <KAlert info title="Informação">
        O relatório exibirá os mil primeiros registros encontrados. Refine sua
        busca para melhorar os seus resultados.
      </KAlert>

      <v-row class="mt-2">
        <!-- Pesquisa -->
        <v-col cols="12" md="6">
          <v-text-field
            clearable
            label="Pesquisar"
            persistent-hint
            hint="Pesquisa pelo nome da unidade de consumo"
            v-bind="bind"
            v-model="query.q"
          />
        </v-col>

        <!-- Filtro de Condomínio -->
        <v-col cols="12" md="6">
          <v-autocomplete
            clearable
            label="Condomínio"
            item-text="name"
            item-value="id"
            :items="condominiums"
            v-bind="bind"
            v-model="query.condominium"
          />
        </v-col>

        <!-- Filtro de Status -->
        <v-col cols="12" md="6">
          <v-select
            clearable
            item-text="text"
            item-value="value"
            label="Status da Coleta"
            :items="statuses"
            v-bind="bind"
            v-model="query.status"
          />
        </v-col>

        <!-- Mês de Referência -->
        <v-col cols="12" md="6">
          <KInputMonth
            mandatory
            return-object
            label="Mês de Referência"
            v-bind="bind"
            v-model="monthYear"
          />
        </v-col>
      </v-row>
    </KForm>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";
import KInputMonth from "@/components/KInput/Month";
export default {
  components: {
    KForm,
    KAlert,
    KDialog,
    KInputMonth
  },

  data() {
    return {
      // query
      query: {},
      monthYear: null,
      // dialog
      visible: false,
      loading: false,
      dialogActions: [
        { label: "Gerar Relatório", eventName: "click:download" }
      ],
      // configurações dos inputs
      bind: { dense: true, outlined: true }
    };
  },

  computed: {
    condominiums() {
      return this.$store.getters["condominiums/all"];
    },
    statuses() {
      return this.$store.getters["collections/statuses"];
    },
    payload() {
      const payload = { ...this.query, ...this.monthYear };

      Object.keys(payload).forEach(key => {
        if (!payload[key]) delete payload[key];
      });

      return payload;
    }
  },

  methods: {
    async onDownloadClick() {
      try {
        this.loading = true;

        await this.$api.download(
          "/collections/report",
          this.payload,
          "relatorio.xlsx"
        );

        this.$message.success("Relatório gerado com sucesso");
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    open() {
      this.$store.dispatch("condominiums/all");
      this.visible = true;
    },
    close() {
      this.query = {};
      // this.monthYear = null;
      this.visible = false;
    }
  }
};
</script>

<style>
</style>
