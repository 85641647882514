<template>
  <KPage>
    <CollectionView :collection="collect" />
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import CollectionView from "./_shared/CollectionView";

export default {
  components: {
    KPage,
    CollectionView
  },

  data() {
    return {
      loading: false,
      collect: {}
    };
  },

  //
  methods: {
    /**
     * Carrega os dados pela primeira vez
     */
    async loadData() {
      try {
        this.loading = true;
        this.$loading.show();

        const { data } = await this.$api.get(
          `/collections/${this.$route.params.id}`
        );

        this.collect = data.data;

        this.$app.title(`Coleta #${this.$route.params.id}`);

        this.$loading.hide();
        this.loading = false;
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
        this.loading = false;
      }
    }
  },

  beforeMount() {
    this.loadData();
  }
};
</script>
