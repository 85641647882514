
<template>
  <KPage>
    <KForm>
      <!-- Filtro -->
      <v-row class="ma-0 mb-4">
        <v-col cols="12" class="py-4">
          <SectionHeader title="Mês/Ano de Referência" />
        </v-col>
        <v-col cols="12" sm="6" class="py-1">
          <KInputMonth
            v-model="monthYear"
            outlined
            dense
            hint="Clique para selecionar o mês/ano de referência para o lote que será gerado"
            persistent-hint
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-1">
          <v-switch
            inset
            label="Mostrar somente pendentes"
            hint="Lista somente os condomínios que não possuem lotes gerados para o mês/ano selecionado"
            persistent-hint
            class="my-1"
            v-model="onlyPendents"
          />
        </v-col>
      </v-row>

      <!-- Cabeçalho -->
      <v-row class="ma-0">
        <v-col cols="12" class="py-0">
          <SectionHeader
            class="my-3"
            title="Condomínios Pendentes"
            subtitle="Listagem dos condomínios que ainda não possuem coletas geradas para este mês de referência"
            :actions="[
              {
                eventName: 'click:refresh',
                icon: 'mdi-refresh',
                label: 'Atualizar lista de condomínios',
              },
            ]"
            @click:refresh="load"
          />
        </v-col>

        <!-- Lista de condomínios -->
        <v-col cols="12" class="py-0">
          <v-card outlined>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="condominiums"
              hide-default-footer
              item-key="id"
              show-select
              no-data-text="Nenhum condomínio pendente"
              outlined
            >
              <!-- eslint-disable vue/valid-v-slot  -->
              <template v-slot:item.chips="{ item }">
                <!--  -->
                <KChip purple>{{
                  item.contracts_count | quantity("contratos", "contrato")
                }}</KChip>
                <!--  -->
                <KChip v-if="item.pendents_count" orange>{{
                  item.pendents_count
                    | quantity("coletas pendentes", "coleta pendente")
                }}</KChip>
                <!--  -->
                <KChip v-if="item.batches_count" red icon="mdi-alert"
                  >Já possui lote no mês selecionado</KChip
                >
                <!--  -->
                <!-- <KChip orange>{{ item.product && item.product.name }}</KChip> -->
              </template>

              <template v-slot:item.last_price="{ item }">
                <KInputMoney
                  dense
                  prefix="R$"
                  solo
                  :disabled="
                    !payload.condominiums.some(
                      (condominium) => condominium.id == item.id
                    )
                  "
                  flat
                  hide-details
                  v-model="item.last_price"
                />
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <!-- <pre
        >{{ info }}
      </pre> -->
      <!-- <pre
        >{{ payload }}
      </pre> -->

      <div style="height: 1px; width: 100%"></div>

      <!-- Aviso de coletas pendentes -->
      <v-expand-transition>
        <v-row class="ma-0 justify-center" v-if="!info && !hasErrors">
          <v-col>
            <KAlert title="Informação" info>
              Selecione os condomínios para gerar lotes de solicitação de
              coletas.
            </KAlert>
          </v-col>
        </v-row>
      </v-expand-transition>

      <!-- Aviso de coletas pendentes -->
      <v-expand-transition>
        <v-row class="ma-0 justify-center" v-if="info && info.pendentsCount">
          <v-col>
            <KAlert title="Atenção" error>
              Existem
              {{ info.pendentsCount }} coletas pendentes dentre os condomínios
              selecionados. É necessário coletá-las ou cancelá-las antes de
              prosseguir.
            </KAlert>
          </v-col>
        </v-row>
      </v-expand-transition>

      <!-- Aviso de coletas com valor zerado -->
      <v-expand-transition>
        <v-row class="ma-0 justify-center" v-if="info && info.emptyPriceCount">
          <v-col>
            <KAlert title="Atenção" error>
              Existem
              {{ info.emptyPriceCount }} condomínios com valor do m³ zerado
            </KAlert>
          </v-col>
        </v-row>
      </v-expand-transition>

      <!-- Resumo da geração -->
      <v-expand-transition>
        <v-row class="ma-0 justify-center" v-if="info && !hasErrors">
          <v-col cols="12" class="py-1">
            <SectionHeader title="Resumo do Lote" />
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-card outlined width="100%">
              <v-card-text class="pb-0">
                Serão geradas
                <b
                  >{{
                    info.contractsCount
                      | quantity("solicitações", "solicitação")
                  }}
                  de coleta
                </b>
                em
                <b>{{
                  info.selectedCount | quantity("condomínios", "condomínio")
                }}</b>
                . Confira as informações abaixo e clique em
                <b>Gerar Coletas</b> para continuar.
              </v-card-text>
              <v-card-text class="py-0">
                <KChip blue>Mês: {{ form.month }}</KChip>
                <KChip blue>Ano: {{ form.year }}</KChip>
                <KChip cyan>{{
                  info.selectedCount | quantity("Condomínios", "Condomínio")
                }}</KChip>
                <KChip purple
                  >{{ info.contractsCount | quantity("Contratos", "Contrato") }}
                </KChip>
              </v-card-text>

              <v-card-text class="d-flex justify-center">
                <v-btn rounded color="accent" elevation="0" @click="save"
                  >Gerar Coletas</v-btn
                >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-expand-transition>

      <!--  -->
      <div style="width: 100%; height: 20px"></div>
    </KForm>

    <!-- <h4>payload</h4>
    {{ payload }} -->

    <!-- DIalog -->
    <KDialog
      title="Coleta em Lote"
      :visible="batchResultVisible"
      showClose
      @click:close="batchResultVisible = false"
    >
      <KAlert
        success
        class="mt-5"
        title="Sua coleta em lote foi gerada com sucesso"
      >
        <p class="my-0">
          <b
            >{{
              batchResult.batchesCount
                | quantity("lotes foram criados", "lote foi criado")
            }}
          </b>

          com um total de
          <b
            >{{
              batchResult.collectionsCount
                | quantity("requisições", "requisição")
            }}
            de coleta
          </b>
          em

          <b>{{ batchResult.duration }}</b>
        </p>
      </KAlert>
    </KDialog>
  </KPage>
</template>

<script>
import KForm from "@/components/KForm";
import KPage from "@/components/KPage";
import KChip from "@/components/KChip";
import KAlert from "@/components/KAlert";
import SectionHeader from "@/components/SectionHeader";
import KInputMonth from "@/components/KInput/Month";
import KInputMoney from "@/components/KInput/Money";
import KDialog from "@/components/KDialog";

export default {
  name: "PageBatch",

  components: {
    KForm,
    KPage,
    KChip,
    KAlert,
    SectionHeader,
    KInputMonth,
    KInputMoney,
    KDialog
  },

  data() {
    return {
      form: {},
      errors: [],
      loading: false,
      monthYear: null,
      condominiums: [],
      selected: [],
      // dialog
      batchResultVisible: false,
      batchResult: {},
      onlyPendents: true,
      headers: [
        {
          text: "Condomínio",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "", value: "chips", sortable: false },
        { text: "Produto", value: "product.name", sortable: false },
        { text: "R$/m³", value: "last_price", sortable: false }
      ]
    };
  },

  watch: {
    monthYear() {
      const [year, month] = this.monthYear.split("-");
      this.$set(this.form, "month", month);
      this.$set(this.form, "year", year);
      this.load();
    },
    onlyPendents() {
      this.load();
    }
  },

  computed: {
    payload() {
      return {
        month: Number(this.form.month),
        year: Number(this.form.year),
        condominiums: this.selected
          // .filter(item => item.selected)
          .map(item => ({
            id: item.id,
            unit_price: item.last_price
          }))
      };
    },

    info() {
      if (!this.selected.length) return null;
      return {
        // Número de condomínios selecionados
        selectedCount: this.selected.length,
        // Número de contratos nos condomínios selecionados
        contractsCount: this.selected.reduce(
          (accu, curr) => accu + curr.contracts_count,
          0
        ),
        // Número de coletas pendentes nos condomínios selecionados
        pendentsCount: this.selected.reduce(
          (accu, curr) => accu + curr.pendents_count,
          0
        ),
        // Número de condomínios com valor do m³ igual a zero
        emptyPriceCount: this.selected.filter(item => +item.last_price === 0)
          .length
      };
    },

    /**
     * Tem erros?
     * Concentra a verificação de erros para que as mensagens sejam exibidas corretamente
     * na tela.
     * Deve verificar:
     * - Se existe algum condomínio com coletas pendentes
     * - Se existe algum condomínio com valor de coleta zerado
     */
    hasErrors() {
      return this.info && (this.info.pendentsCount || this.info.emptyPriceCount)
        ? true
        : false;
    }
  },

  methods: {
    /**
     *
     *
     */
    async save() {
      try {
        // Confirma?
        if (
          !confirm(
            `Serão geradas ${this.info.contractsCount} coleta(s) em ${this.info.selectedCount} condomínios. Deseja continuar?`
          )
        ) {
          this.$message.show("Geração de coletas em lote cancelada");
          return;
        }
        this.$loading.show();
        this.errors = {};
        this.batchResult = {};
        // console.log(this.payload);

        const { data } = await this.$api.post(
          "/collections/batch",
          this.payload
        );

        // Insere o resultado da inserção no state
        this.batchResult = data.data;
        // Abre o dialog
        this.batchResultVisible = true;
        this.selected = [];

        await this.load();

        this.$loading.hide();
        this.$message.success("Coletas em lote geradas com sucesso");
        // if (!this.isEditing) {
        //   // console.log("é edita", collection);
        //   this.$router.push(`/collect/collections/view/${collection.id}`);
        // }
      } catch (error) {
        this.$loading.hide();
        this.errors = this.$message.serverError(error);
        // console.log("erro ao salvar", errors);
        console.log(error);
      }
    },

    /**
     *
     *
     */
    async load() {
      try {
        this.$loading.show();

        const date = new Date();
        if (!this.monthYear) {
          this.monthYear = `${date.getFullYear()}-${String(
            date.getMonth() + 1
          ).padStart(2, "0")}`;
        }

        const { data } = await this.$api.get(
          "/collections/batch/condominiums",
          {
            month: this.form.month,
            year: this.form.year,
            only_pendents: this.onlyPendents
          }
        );

        this.condominiums = data.data;
        this.selected = [];

        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
      }
    }
  },
  async beforeMount() {
    this.load();
  }
};
</script>

<style>
.v-list-item.border {
  border-bottom: 1px solid #ddd;
}
</style>
